@use "../../style/variables.scss" as *;
@use "../../style/mixin.scss" as mixin;

$check-box-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out;
  padding: 150px 0;
  opacity: 0;
  visibility: hidden;
  animation: fadeOut 0.3s;
  z-index: 1;
  &.show {
    opacity: 1;
    visibility: visible;
    animation: fadeIn 0.3s;
  }
}

.modal {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  max-width: 457px;
  // width: 100%;
  background-color: white;
  padding: 15px;
  margin: 200px 5px;
  border-radius: 10px;
  border: 4px solid $theme-color;
  // gap: 20px;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  animation: fadeInUp 0.3s ease-in-out forwards;

  &--animated {
    animation: resizeWidth 0.3s ease-in-out, resizeHeight 0.3s ease-in-out;
  }

  @media (max-width: 440px) {
    padding: 10px;
  }

  &__title {
    @include mixin.articleTitle;
    font-size: 20px;
    z-index: 1;
  }

  &__cross {
    @include mixin.cross;
  }

  &__img {
    max-width: 300px;
    height: auto;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    &:hover {
      transform: scale(1.01);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
    &:active {
      transform: scale(1);
    }
  }

  &__tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    flex-wrap: wrap;
    gap: 7px;
  }

  &__btn {
    position: relative;
    padding: 10px 20px;
    border: none;
    background-color: transparent;
    color: $theme-color;
    font-size: 17px;
    cursor: pointer;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    transition: color 0.3s, border-bottom 0.3s;
    &:hover,
    &.active {
      .modal__span {
        opacity: 1;
        transform: scale(1);
      }
    }

    &:hover .modal__span {
      opacity: 1;
      transform: scale(1);
    }
  }

  &__span {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 2px solid $theme-color;
    border-radius: 7px;
    transform: scale(0);
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0;
  }
}

@keyframes resizeWidth {
  from {
    width: auto;
  }
  to {
    width: 100%;
  }
}

@keyframes resizeHeight {
  from {
    height: auto;
  }
  to {
    height: 100%;
  }
}

.loginForm {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  padding: 15px;
  max-height: 500px;
  gap: 10px;

  &::-webkit-scrollbar {
    width: 7px;
    background-color: lighten($theme-color, 50%);
  }

  &::-webkit-scrollbar-thumb {
    background-color: $theme-color;
    border-radius: 5px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba($theme-color, 0.1);
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;

    @media (max-width: 440px) {
      justify-content: center;
      flex-direction: column;
      gap: 5px;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__input-container {
    display: flex;
    align-items: center;
    border-radius: 5px;
    box-shadow: inset 0 0 5px rgba(black, 0.2);
  }

  &__input {
    box-shadow: inset 0 0 5px rgba(black, 0.2);
    margin-bottom: 10px;
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    letter-spacing: 1px;
    outline: none;

    &::placeholder {
      font-style: italic;
    }

    &--noShadow {
      box-shadow: none;
      margin-bottom: 0;
      background: transparent;
    }

    &--error {
      border-color: red;
      box-shadow: inset 2px 2px 7px rgba(255, 0, 0, 0.3);
      background-color: rgba(red, 0.05);
    }

    &--success {
      border-color: green;
      box-shadow: inset 2px 2px 7px rgba(darkgreen, 0.3);
      background-color: rgba(green, 0.05);
    }
  }

  &__text {
    &--bold {
      font-weight: bold;
      text-align: center;
    }
    &--italic {
      font-style: italic;
      font-size: 12px;
      text-align: center;
    }
  }

  &__label {
    font-size: 14px;
  }

  &__icon {
    width: 23px;
    background: white;
    box-shadow: none;
    margin-right: 8px;
    cursor: pointer;
  }

  &__btn {
    padding: 10px 20px;
    background-color: $theme-color;
    color: white;
    border: none;
    font-size: 17px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);

    &:hover {
      background-color: darken($theme-color, 10%);
      transform: translateY(2px);
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    }

    &:active {
      background-color: darken($theme-color, 20%);
    }
  }

  &__offer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    color: black;
    font-family: "Oswald", sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
    border: 1px solid rgb($theme-color, 0.4);
    border-radius: 7px;
    padding: 4px 8px;
    cursor: pointer;
    gap: 5px;

    &:hover {
      color: $theme-color;
    }
  }

  &__offer-img {
    width: 25px;
    height: 25px;
  }
}

.permission {
  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 7px;
    border: 1px solid $theme-color;
    padding: 15px;
    margin: 15px 0;
    gap: 15px;
  }

  &__underlined {
    text-decoration: underline;
  }

  &__warning {
    font-style: italic;
    font-size: 14px;
    text-align: center;
  }

  &__textWrapper {
    display: flex; /* Утримує текст і кнопку в одному рядку */
    align-items: center; /* Вирівнює елементи по вертикалі */
  }

  &__label {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    font-size: 14px;
    cursor: pointer;
  }

  // CHECKBOX
  &__input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  // for labels connected to &__input
  &__input + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    color: black; // fix for colored checkboxes
  }
  // for labels - before
  &__input + label::before {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin: 0 0.7em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  // hover on checkbox
  &__input:not(:disabled):not(:checked) + label:hover::before {
    border-color: $theme-color;
  }
  // active checkbox
  &__input:not(:disabled):active + label::before {
    background-color: lighten($theme-color, 44%);
    border-color: lighten($theme-color, 44%);
  }
  //  checkbox in focus
  &__input:focus + label::before {
    box-shadow: 0 0 0 0.2rem lighten($theme-color, 44%);
  }
  //  checkbox in focus (not checked)
  &__input:focus:not(:checked) + label::before {
    border-color: $theme-color;
  }
  //  checkbox in focus (checked)
  &__input:checked + label::before {
    border-color: $theme-color;
    background-color: $theme-color;
    background-image: $check-box-icon;
    scale: 1.1;
  }
  // checkbox (disabled)
  &__input:disabled + label::before {
    background-color: $theme-color;
  }
}

.description {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  gap: 10px;

  &__title {
    @include mixin.articleTitle;
    font-size: 25px;
  }
  &__subTitle {
    text-align: center;
    color: $theme-color;
  }
  &__text {
    text-align: justify;
  }
}

@keyframes expandHeight {
  from {
    height: 0;
  }
  to {
    height: 100px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
